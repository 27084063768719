<template>
  <v-container :style="{
    marginBottom: showMargin ? props.styles.marginBottom + 'px' : 'auto',
    marginTop: showMargin ? props.styles.marginTop + 'px' : 'auto',
    marginLeft: showMargin ? props.styles.marginLeft + 'px' : 'auto',
    marginRight: showMargin ? props.styles.marginRight + 'px' : 'auto',
    borderLeftWidth: props.styles.borderLeftWidth + 'px',
    borderTopWidth: props.styles.borderTopWidth + 'px',
    borderBottomWidth: props.styles.borderBottomWidth + 'px',
    borderRightWidth: props.styles.borderRightWidth + 'px',
    borderColor: props.styles.borderColor,
    borderStyle: props.styles.borderStyle,
    
  }" style="margin: 0 auto">
    <v-row :class="props.alignment" :style="{
      paddingBottom: showPadding ? props.styles.paddingBottom + 'px' : 'auto',
      paddingTop: showPadding ? props.styles.paddingTop + 'px' : 'auto',
      paddingLeft: showPadding ? props.styles.paddingLeft + 'px' : 'auto',
      paddingRight: showPadding ? props.styles.paddingRight + 'px' : 'auto',
      '@media (max-width: 768px)': {
        marginLeft: showMargin ? props.styles.mobileLeft + 'px' : 'auto',
        marginRight: showMargin ? props.styles.mobileRight + 'px' : 'auto',
        marginTop: showMargin ? props.styles.mobileTop + 'px' : 'auto',
        marginBottom: showMargin ? props.styles.mobileBottom + 'px' : 'auto',
        paddingLeft: showPadding ? props.styles.mobilePaddingLeft + 'px' : 'auto',
        paddingRight: showPadding ? props.styles.mobilePaddingRight + 'px' : 'auto',
        paddingTop: showPadding ? props.styles.mobilePaddingTop + 'px' : 'auto',
        paddingBottom: showPadding ? props.styles.mobilePaddingBottom + 'px' : 'auto',
      },

    }" class="ma-0 d-flex flex-column">
      <template v-if="children.length">
        <recursion v-for="(ch, index) in children" :key="index" :content="ch"
          :componentName="ch.props.component || ch.componentName" />
      </template>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => {
    return {
      showMargin: false,
      showPadding: false,
    };
  },
  props: [
    'children',
    'props',
    'showMargin',
    'showPadding',
    'showBorder',
  ],
  watch: {
    showMargin(newValue) {
      if (!newValue) {
        this.$emit('onMarginLeftChange', 'auto');
        this.$emit('onMarginRightChange', 'auto');
        this.$emit('onMarginTopChange', 'auto');
        this.$emit('onMarginBottomChange', 'auto');
      }
    },
    showPadding(newValue) {
      if (!newValue) {
        this.$emit('onPaddingLeftChange', 'auto');
        this.$emit('onPaddingRightChange', 'auto');
        this.$emit('onPaddingTopChange', 'auto');
        this.$emit('onPaddingBottomChange', 'auto');
      }
    },
  },
};
</script>

<style></style>
