export default {
  orderHistoryItems: [
    // {
    //   id: 7,
    //   orderDate: Date.now(),
    //   orderNumber: 23415,
    //   orderStatus: 'Обработка',
    //   pricePerOne: 40,
    //   wholesale: false,
    // },
  ],
  savedOrder: null,
  retailOrderDetails: {},
  deliveryPrice: {
    delivery_view: 0,
  },
}
