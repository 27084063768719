import axios from '@/core/services/api.service'

export default {
  addOrderHistoryItem({ commit }, item) {
    commit('ADD_ORDER_HISTORY_ITEM', item)
  },
  createOrder({ commit }, { items, shippingPrice, delivery }) {
    // FIXME:
    const url = '/wholesale/order'
    const result = {
      sum: 0,
      count: 0,
      delivery: delivery,
      from: '',
      items: [],
      reyestr_id: null,
      status: 'Processing',
      comment: 'comment info',
    }
    items.forEach((item) => {
      result.reyestr_id = item.id
      result.count += item.sizes.reduce((sum, curr) => (sum += curr.count), 0)
      let sum = 0
      sum += item.sizes.reduce(
        (sum, curr) => (sum += curr.count * curr.price),
        0
      )

      if (item.price_type === 'reyestr' && item.selected_brands) {
        if (item.selected_brands && item.selected_brands?.length) {
          sum += item.selected_brands.reduce(
            (sum, curr) => (sum += +curr.brandPrice),
            0
          )
        }
      } else {
        item.soles.forEach((el) => {
          if (el.selected_brands && el.selected_brands?.length) {
            sum += el.selected_brands.reduce((sum, curr) => {
              if (curr.count) {
                if (curr.isEnough === 'enough') {
                  return (sum += +curr.brandPrice * +curr.selected_count)
                } else {
                  return (sum +=
                    +curr.brandPrice * +curr.selected_count + +curr.brandsPrice)
                }
              } else {
                return (sum += curr.brandPrice)
              }
            }, 0)
          }
        })
      }

      result.sum += Math.floor(sum)
      if (item.price_type === 'sole') {
        item.soles.forEach((el) => {
          item.sizes
            .filter((s) => s.soleId === el.id)
            .forEach((size, index) => {
              const color = item.colors.find(
                (color) => size.colorId === color.id
              )
              let newItem = {
                reyestr_id: item?.id,
                color: color?.name,
                size: size?.name,
                count: size.count,
                price: String(size.price),
              }
              if (el?.selected_brands) {
                el.selected_brands.forEach((element) => {
                  if (el?.selected_brands.length < 1) {
                    result.items.push(newItem)
                  }
                  newItem.sole_id = el?.id
                  newItem.sole_brand_id = element.id
                  result.items.push({
                    ...newItem,
                  })
                })
              } else {
                newItem.sole_id = el?.id
                result.items.push(newItem)
              }
            })
        })
      } else {
        item.sizes.forEach((size, index) => {
          const color = item.colors.find((color) => size.colorId === color.id)
          let newItem = {
            reyestr_id: item?.id,
            color: color?.name,
            size: size?.name,
            count: size.count,
            price: String(size.price),
          }
          if (item?.selected_brands?.length >= 1) {
            item.selected_brands.forEach((element) => {
              if (item.selected_brands.length < 1) {
                result.items.push(newItem)
              }
              newItem.brand_id = element.id
              result.items.push({
                ...newItem,
              })
            })
          } else {
            result.items.push(newItem)
          }
        })
      }
    })
    result.from = 'catalog'
    commit('SET_SAVED_ORDER', result)
    return axios.post(url, result, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=utf-8',
      },
    })
  },
  createRetailOrder() {
    const url = '/frontend/order/retail'
  },
  fetchRetailOrderHistory() {
    const url = '/frontend/order/retail'
    return axios.get(url).then((res) => {
      commit('SET_ORDER_HISTORY_ITEMS', res.data.data)
    })
  },
  fetchOrderHistory({ commit }, page) {
    const url = `/wholesale/order?page=${page}`
    return axios.get(url).then((res) => {
      commit('SET_ORDER_HISTORY_ITEMS', res.data)
    })
  },
  async fetchOrderById(_, id) {
    const url = `/wholesale/order/${id}`
    const response = await axios.get(url)
    return response.data
  },

  // retail orders

  async createRetailOrder({ commit }, { userInfo, products, delivery }) {
    const result = {
      customer: {
        first_name: userInfo.first_name,
        last_name: userInfo.last_name,
        phone: userInfo.phone,
        email: userInfo.email,
        country_id: userInfo.country_id,
        address: userInfo.address,
        locality: userInfo.locality,
        wine_region: userInfo.wine_region,
        zip_code: userInfo.zip_code,
        tax_number: userInfo.tax_number,
      },
      products: products,
      delivery,
    }
    const response = await axios.post(`/frontend/order/retail`, result, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=utf-8',
      },
    })
    return response
  },
  getRetailOrderById({ commit }, { orderId }) {
    const url = `/frontend/order/retail/${orderId}`
    return axios.get(url).then((res) => {
      commit('SET_RETAIL_ORDER_DETAILS', res.data)
    })
  },
  fetchOrderHistory({ commit }, page) {
    const url = `/wholesale/order?page=${page}`
    return axios.get(url).then((res) => {
      commit('SET_ORDER_HISTORY_ITEMS', res.data)
    })
  },
  payByPaypal({ commit }) {
    const url = '/frontend/retail/payment/paypal'
    return axios
      .post(url, {
        order_id: 1,
        success_url: 'https://www.google.com',
        cancel_url: 'https://www.youtube.com/',
      })
      .then((res) => {
        console.log(res)
      })
  },
  fetchDeliveryPrice({ commit }, data) {
    const url = `/frontend/delivery/retail/calc`
    return axios.post(url, data).then((res) => {
      commit('SET_DELIVERY_PRICE', res.data)
    })
  },
}
