export default {
  ADD_ORDER_HISTORY_ITEM(state, item) {
    const existingItem = state.orderHistoryItems.find((i) => i.id === item.id)
    if (!existingItem) {
      state.orderHistoryItems.push(item)
    }
  },
  SET_ORDER_HISTORY_ITEMS(state, items) {
    state.orderHistoryItems = items
  },

  SET_SAVED_ORDER(state, payload) {
    state.savedOrder = payload
  },
  SET_RETAIL_ORDER_DETAILS(state, payload) {
    state.retailOrderDetails = payload
  },
  SET_DELIVERY_PRICE(state, payload) {
    state.deliveryPrice = payload
  },
}
