<template>
  <component
    class="crafted-component"
    :is="components[componentName]"
    :props="content.props"
    :children="content.children"
    :style="content.props.styles"
    :content="content"
    :class="content.props.classes"
  >
    <template v-if="content.children.length">
      <recursion
        v-for="(element, index) in content.children"
        :key="index"
        :content="element"
        :componentName="
          element.props.component
            ? element.props.component
            : element.componentName
        "
      />
    </template>
  </component>
</template>

<script>
import Container from '@/view/pages/builder/Container.vue'
import Tabs from '@/view/pages/builder/Tabs.vue'
import NestedContainer from '@/view/pages/builder/NestedComponent.vue'
import Paragraph from '@/view/pages/builder/Paragraph.vue'
import Divider from '@/view/pages/builder/Divider.vue'
import Button from '@/view/pages/builder/Button.vue'
import Images from '@/view/pages/builder/Images.vue'
import Gallery from '@/view/pages/builder/Gallery.vue'
import Accordion from '@/view/pages/builder/Accordion.vue'
import Form from '@/view/pages/builder/Form.vue'
import MailingForm from '@/view/pages/builder/MailingForm.vue'
import Icon from '@/view/pages/builder/Icon.vue'
import ImageComponent from '@/view/pages/builder/ImageComponent.vue'
import VideoComponent from '@/view/pages/builder/VideoComponent.vue'
import Banner from '@/view/pages/builder/Banner.vue'
import Blogs from '@/view/pages/builder/Blogs.vue'
import Carousel from '@/view/pages/builder/Carousel.vue'
import Tour from '@/view/pages/builder/Tour.vue'
import BigSlider from '@/view/pages/builder/BigSlider.vue'
import Testing from '@/view/pages/builder/Testing.vue'
import TestCol from '@/view/pages/builder/TestCol.vue'
import Products from '@/view/pages/builder/Products.vue'
import PlainImage from '@/view/pages/builder/PlainImage.vue'
import GridImages from '@/view/pages/builder/GridImages.vue'

export default {
  components: {
    Container,
    Tabs,
    NestedContainer,
    Paragraph,
    Divider,
    Button,
    Images,
    Accordion,
    Form,
    MailingForm,
    Icon,
    Gallery,
    ImageComponent,
    VideoComponent,
    Banner,
    Blogs,
    Carousel,
    Tour,
    BigSlider,
    PlainImage,
    GridImages,
    Products,
  },
  props: {
    content: {
      type: Object,
      default: () => {},
    },
    componentName: {
      type: String,
      default: '',
    },
  },
  computed: {
    components() {
      return {
        Container,
        Tabs,
        NestedContainer,
        Paragraph,
        Divider,
        Button,
        Images,
        Accordion,
        Form,
        MailingForm,
        Icon,
        Gallery,
        ImageComponent,
        VideoComponent,
        Banner,
        Blogs,
        Carousel,
        Tour,
        BigSlider,
        Testing,
        TestCol,
        PlainImage,
        GridImages,
        Products,
      }
    },
  },
}
</script>

<style lang="scss">
.crafted-component {
  .ql-align-center {
    text-align: center;
  }
  .ql-align-right {
    text-align: right;
  }
  ul,
  li {
    list-style: unset;
  }
  .ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 3em;
  }
  .ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 6em;
  }
  .ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 9em;
  }
  .ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 12em;
  }
  .ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 15em;
  }
  .ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 18em;
  }
  .ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 21em;
  }
  .ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 24em;
  }
  blockquote {
    border-left: 4px solid #ccc;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-left: 16px;
  }
  pre.ql-syntax {
    background-color: #23241f;
    color: #f8f8f2;
    overflow: visible;
    white-space: pre-wrap;
    margin-bottom: 5px;
    margin-top: 5px;
    padding: 5px 10px;
    border-radius: 3px;
  }
  h5 {
    font-weight: 400;
    line-height: 1.5em;
    font-size: 18px;
  }

}
</style>
